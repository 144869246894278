define('ember-can/helpers/can', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    can: Ember.inject.service(),

    ability: null,
    propertyName: null,

    compute([abilityString, model], properties) {
      let service = this.get('can');

      var _service$parse = service.parse(abilityString);

      let abilityName = _service$parse.abilityName,
          propertyName = _service$parse.propertyName;

      let ability = service.abilityFor(abilityName, model, properties);

      this._removeAbilityObserver();
      this._addAbilityObserver(ability, propertyName);

      return ability.get(propertyName);
    },

    destroy() {
      this._removeAbilityObserver();
      return this._super(...arguments);
    },

    _addAbilityObserver(ability, propertyName) {
      this.setProperties({ ability, propertyName });
      this.addObserver(`ability.${propertyName}`, this, 'recompute');
    },

    _removeAbilityObserver() {
      this.removeObserver(`ability.${this.get('propertyName')}`, this, 'recompute');
    }
  });
});