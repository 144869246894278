define('ember-can/utils/normalize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (string) {
    let parts = string.split(' ');
    let abilityName = parts.pop();
    let last = parts[parts.length - 1];

    if (stopWords.includes(last)) {
      parts.pop();
    }

    let ability = Ember.String.classify(parts.join(' '));
    let propertyName = `can${ability}`;

    return { propertyName, abilityName };
  };

  const stopWords = ['of', 'in', 'for', 'to', 'from', 'on'];
});