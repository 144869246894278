define('ember-can/initializers/setup-ember-can', ['exports', 'ember-resolver'], function (exports, _emberResolver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  _emberResolver.default.reopen({
    init() {
      this._super(...arguments);
      this.set('pluralizedTypes.ability', 'abilities');
    }
  });

  function initialize() {}
  exports.default = { initialize };
});