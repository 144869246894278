define('ember-can/index', ['exports', 'ember-can/ability', 'ember-can/mixins/can', 'ember-can/computed', 'ember-can/services/can'], function (exports, _ability, _can, _computed, _can2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.computed = exports.CanService = exports.CanMixin = exports.Ability = undefined;


  // Deprecations

  // Deprecated usage of CanMixin
  function computed() {
    Ember.deprecate('Importing "computed" from ember-can is deprecated and will be removed. Please migrate to `import { ability } from "ember-can/computed";`', false, {
      id: 'ember-can.deprecate-computed-import',
      until: '2.0.0',
      url: 'https://github.com/minutebase/ember-can#looking-up-abilities'
    });

    return (0, _computed.ability)(...arguments);
  }

  // Deprecated import of 'CanService' service


  // Deprecated import of 'computed' function


  let CanService = _can2.default.extend({
    init() {
      this._super(...arguments);

      Ember.deprecate('Importing "CanService" from ember-can is deprecated and will be removed. Please migrate to `import CanService from "ember-can/services/can";`', false, {
        id: 'ember-can.deprecate-service-import',
        until: '2.0.0',
        url: 'https://github.com/minutebase/ember-can#looking-up-abilities'
      });
    }
  });

  exports.Ability = _ability.default;
  exports.CanMixin = _can.default;
  exports.CanService = CanService;
  exports.computed = computed;
});